@import "~@ckeditor/ckeditor5-theme-lark/theme/mixins/_rounded.css";
@import "~@ckeditor/ckeditor5-theme-lark/theme/mixins/_focus.css";
@import "~@ckeditor/ckeditor5-theme-lark/theme/mixins/_shadow.css";
@import "~@ckeditor/ckeditor5-theme-lark/theme/mixins/_disabled.css";

:root {
	--ck-color-pagination-divider: hsl(0, 0%, 67%);
}

/*
 * Content styles.
 */

.ck.ck-pagination_enabled {
	/* &.ck-content .page-break::after {
		display: none;
	} */
	
	&.ck-editor__editable.ck-pagination-view {
		& .ck-widget__type-around {
			display: none;
		}

		& *:has(+ .page-break) {
			margin-bottom: 0;
		}

		& .page-break {
			padding: 0;
			margin: 0;
			break-after: column;
			display: block;
			height: 0;

			& .page-break__label {
				display: none;
			}

			&::after {
				display: none;
			}

		}

		& .ck-table-column-resizer {
			display: none;
		}

		& .table table {

			/*
			 * Tables that have overflow set to hidden are incorrectly split between pages on the chrome. Reset the overflow to initial fixes this issue.
			 *
			 * See: https://github.com/ckeditor/ckeditor5/issues/14426.
			 */
			overflow: initial;
		}

		/*
		 * Sometimes Chrome incorrectly calculates the height of the last table row and some
		 * of the content overlaps the paragraph that is placed directly after the table. It affects
		 * the column split mode pagination calculation which causes mismatch between the print mode and
		 * the preview mode.
		 *
		 * This issue happens only if:
		 *
		 * 	* The table is inside a figure element.
		 * 	* The table has `display: table` style set.
		 * 	* The block element is placed directly after the table.
		 *
		 * The print mode is not affected, the issue happens only in the column split mode.
		 *
		 * See: https://github.com/cksource/ckeditor5-commercial/issues/6707
		 */
		& figure.table:has(> table) {
			display: block;
		}
	}
}


.ck.ck-page-navigator {
	padding: 0 var(--ck-spacing-standard);
	line-height: 1;

	& > .ck-input-number {
		width: var(--ck-ui-component-min-height);
		min-width: var(--ck-ui-component-min-height);

		background: transparent;
		text-align: center;

		/* Disable native increment/decrement buttons (FF). */
		-moz-appearance: textfield;

		/* Disable native increment/decrement buttons (Webkit). */
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:not(:focus) {
			border: 1px solid transparent;
			border-bottom: 1px solid var(--ck-color-base-border);
		}
	}

	& > span::before {
		content: '/';
		margin: 0 var(--ck-spacing-standard);
	}
}

.ck.ck-pagination-view-line {
	position: absolute !important;
	height: 0 !important;
	border-top: 1px dotted var(--ck-color-pagination-divider) !important;
	pointer-events: none !important;

	&::after {
		content: attr(data-page-number);
		font-size: 10px;
		background: hsl(0, 0%, 77%);
		color: var(--ck-color-base-background);
		display: inline-block;
		position: absolute;
		right: -1px;
		transform: translateX(100%);
		min-width: 18px;
		text-align: center;
		top: -1px;
		padding: var(--ck-spacing-tiny) var(--ck-spacing-medium);
		z-index: 600;
	}
}

h2 {
	font-size: 25px;
}

h3 {
	font-size: 19px;
}

h4 {
	font-size: 13px;
}
